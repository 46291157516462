<template>
  <v-main>
    <v-container>
      <v-dialog v-model="dialogs.information" max-width="600">
        <v-card class="mx-auto">
          <v-card-title
            >Land Information
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <UpdateLandInformation
              :id="$route.params.id"
              :update="!notFound"
              @success="onUpdate"
              @cancel="closeDialog"
            />
          </v-card-text>
        </v-card>
      </v-dialog>

      <feature-breadcrumb :feature="fBreadcrumb">
        <template #action>
          <base-prop-tooltip tooltip="Land information">
            <LandChip :type="featureType" @click="dialogs.information = true" small />
          </base-prop-tooltip>
          <ParcelReference small :reference="parcelReference" />
          <FeatureGalleryButton
            class="mx-2"
            camera
            v-model="gallery"
            :count="galleryCount"
            @click:camera="onClickCamera"
          />
          <v-btn
            icon
            @click="$refs.gallery.showActionDialog()"
            v-if="$store.state.loggedInAs.type === 'organization'"
          >
            <v-icon>mdi-arrow-decision-outline</v-icon>
          </v-btn>
        </template>
      </feature-breadcrumb>

      <v-row>
        <v-col cols="12">
          <FeatureGallery
            :feature="feature"
            v-model="gallery"
            @update:count="onUpdateGalleryCount"
            @click:land="showLandActivity"
            @upload="updateMapCenter"
            ref="gallery"
          />
          <v-card class="mx-auto mt-2" elevation="3" :color="dialogs.add ? '' : 'primary'">
            <v-card-title class="cursor-pointer" :class="dialogs.add ? '' : 'white--text'" @click="toggleAddDialog()"
              >
              <v-btn icon :color="dialogs.add ? '' : 'white'" v-if="!dialogs.add">
                <v-icon>
                mdi-plus
                </v-icon></v-btn
              >
              <span v-if="dialogs.add">Adding</span>
              <span v-else>Add</span>
              <span class="ml-1"></span>
                Land Activity
              {{ latestParcel && latestParcel.reference ? `for ${latestParcel.reference}` : '' }}
              <v-spacer></v-spacer>
              <v-btn icon :color="dialogs.add ? '' : 'white'">
                <v-icon>{{
                  dialogs.add ? 'mdi-chevron-up' : 'mdi-chevron-down'
                }}</v-icon></v-btn
              >
              </v-card-title
            >

            <v-slide-y-transition hide-on-leave>
              <v-container v-if="dialogs.add">
                <AddActivity :id="$route.params.id" @success="onAdded" label="Add" />
              </v-container>
            </v-slide-y-transition>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <ActivityHistory
            :id="$route.params.id"
            ref="activityHistory"
            @success="onTransactionAdd()"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex';
import Land from '../../../services/land';
import Parcel from '../../../services/parcel';
import Orientation from '../../../services/orientation';
import config from '../../../config';
import center from '@turf/center';
import { parseGeoJSON } from '../../../utils/map';
const land = new Land();
export default {
  components: {
    FeatureBreadcrumb: () => import('@/components/Core/FeatureBreadcrumb.vue'),
    FeatureGallery: () => import('@/components/Gallery/FeatureGallery.vue'),
    FeatureGalleryButton: () => import('@/components/Gallery/FeatureGalleryButton.vue'),
    LandChip: () => import('@/components/Core/Chip/LandChip.vue'),
    UpdateLandInformation: () => import('@/components/Land/UpdateInformation'),
    AddActivity: () => import('@/components/Land/AddActivity'),
    ActivityHistory: () => import('@/components/Land/ActivityHistory'),
    ParcelReference: () => import('../../../components/Parcel/Reference.vue')
  },
  data: () => ({
    feature: 'Land',
    featureInfoText: 'Land Information',
    gallery: false,
    galleryCount: 0,

    dialogs: {
      add: false,
      information: false
    },
    fBreadcrumb: {
      text: 'Land',
      to: ''
    },

    loadingFeatureInformation: true,
    featureInformation: null,
    notFound: false
  }),
  watch: {
    xs() {
      if (!this.xs) {
        this.dialogs.add = true;
      }
    }
  },
  computed: {
    parcelReference() {
      const ref = this.$store.state.parcel.latestParcel?.reference;
      if (ref) {
        return ref;
      }
      return '';
    },
    infoDialog() {
      return this.dialogs.information;
    },
    featureType() {
      if (this.loadingFeatureInformation) {
        return 'Loading...';
      } else {
        if (this.notFound) {
          return 'Update land information';
        } else if (this.featureInformation) {
          return this.featureInformation.type;
        }
      }
      return '';
    },
    xs() {
      return this.$vuetify.breakpoint.xs;
    },
    ...mapGetters(['latestParcel'])
  },
  created() {
    if (this.$route.query.gallery) {
      this.gallery = true;
    }
    if (this.$route.query.add) {
      this.dialogs.add = true;
    }
    this.getInformation();
    const { information } = this.$route.query;
    try {
      if (information && JSON.parse(information) === true) {
        this.dialogs.information = true;
      }
    } catch {}

    if (config.orientation) {
      const orientation = new Orientation();
      setTimeout(() => {
        if (orientation.supports() && !this.$store.state.orientationPermission) {
          this.$store.commit('setDialog', { key: 'orientationPermission', value: true });
        }
      }, 2000);
    }
  },
  mounted() {
    setTimeout(() => {
      this.updateLandMetrics();
    }, 3000);
  },
  methods: {
    showLandActivity(landActivity) {
      if (!landActivity) {
        this.dialogs.add = true;
      }
    },
    async landMetrics() {
      const parcel = this.$route.params.id;
      const land = new Land();
      let newData = {};
      await land.listActivity(parcel);
      try {
        try {
          const landIssueState = land.issueState();
          newData = { ...newData, landIssueState };
        } catch {
          newData.landIssueState = '';
        }

        try {
          const monitoringInfo = await land.monitoringInfo(this.latestParcel?.user);
          newData = { ...newData, ...monitoringInfo, mddUpdated: true };
        } catch {
          newData.mddUpdated = false;
        }
      } catch (error) {
        console.log(error);
      }
      if (this.galleryCount) {
        newData.landGalleryCount = this.galleryCount;
      }
      return newData;
    },
    async updateLandMetrics() {
      const data = await this.landMetrics();
      const action = {
        feature: 'metrics',
        action: 'update',
        parcelData: data
      };
      let update = false;
      for (const key of Object.keys(data)) {
        if (this.latestParcel[key] !== data[key]) {
          update = true;
        }
      }
      if (update) {
        this.updateMetrics(action);
      }
    },
    onTransactionAdd() {
      this.updateParcelLandInfo();
      this.updateMapCenter();
    },
    updateMapCenter() {
      let coordinates = this.latestParcel.center;
      if (!coordinates) {
        try {
          coordinates = center(parseGeoJSON(this.latestParcel.geojson)).geometry.coordinates;
        } catch {
          //
        }
      }
      if (Array.isArray(coordinates) && coordinates[0] && coordinates[1]) {
        const mapCenter = { lng: coordinates[0], lat: coordinates[1] };
        localStorage.setItem('mapCenter', JSON.stringify(mapCenter));
        localStorage.setItem('mapZoom', 16);
        localStorage.setItem('flyToUser', JSON.stringify(true));
      }
    },
    onUpdateGalleryCount(value) {
      this.galleryCount = value;
      if (value && value !== this.latestParcel?.landGalleryCount) {
        this.updateMetrics({
          feature: 'metrics',
          action: 'update',
          parcelData: { landGalleryCount: value }
        });
      }
    },
    onClickCamera() {
      this.$refs.gallery.$refs.gallery.mode = 'camera';
      this.$refs.gallery.$refs.gallery.dialogs.share = true;
    },
    updateParcelLandInfo(action) {
      const parcel = this.$route.params.id;
      const land = new Land();
      let newData = {};
      land.listActivity(parcel).then(async () => {
        try {
          try {
            const landIssueState = land.issueState();
            newData = { ...newData, landIssueState };
          } catch {
            newData.landIssueState = '';
          }

          try {
            const monitoringInfo = await land.monitoringInfo(this.latestParcel?.user);
            newData = { ...newData, ...monitoringInfo, mddUpdated: true };
          } catch {
            newData.mddUpdated = true;
            newData.mdd = null;
            newData.lmd = null;
          }

          const p = new Parcel();
          p.setId(parcel);
          await p.fetch();
          p.setFeatureCount('land', land.activities?.length);
          if (Object.keys(newData).length) {
            await p.update(newData);
          }
        } catch (error) {
          console.log(error);
        }
        if (action) {
          const metricsData = { ...action, parcelData: newData };
          this.updateMetrics(metricsData);
        }
      });
    },
    onAdded(action) {
      this.updateParcelLandInfo(action);
      this.$refs.activityHistory.refresh();
      this.$refs.gallery.showActionDialog();
    },
    closeDialog() {
      this.dialogs.information = false;
      if (this.$route.fullPath !== this.$route.path) {
        this.$router.push(this.$route.path);
      }
    },
    toggleAddDialog() {
      this.dialogs.add = !this.dialogs.add;
    },
    onUpdate(action) {
      this.updateParcelLandInfo(action);
      this.closeDialog();
      this.getInformation();
    },
    getInformation() {
      land
        .detail(this.$route.params.id)
        .then(res => {
          this.featureInformation = res;
        })
        .catch(error => {
          if (error.code === 404) {
            this.notFound = true;
            this.dialogs.information = true;
          }
        })
        .finally(() => {
          this.loadingFeatureInformation = false;
        });
    }
  }
};
</script>

<style scoped>
</style>
